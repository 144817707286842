<template>
  <div class="uebersichtbau">
    <div class="row">
      <div class="flex md12">
        <va-card outlined>
          <template slot="header">
            <h5 class="mt-0 mb-0">{{ $t('menu.uebersichtbau') }}</h5>
          </template>
          <div class="row">
            <va-notification class="flex md10" v-if="currState.msgType=='success'" color="success">
              <va-badge color="success">
                {{ $t('common.notificationSuccess') }}
              </va-badge>
              {{ currState.msg }}
            </va-notification>
            <va-notification class="flex md10" v-if="currState.msgType=='error'" color="danger">
              <va-badge color="danger">
                {{ $t('common.notificationError') }}
              </va-badge>
              {{ currState.msg }}
            </va-notification>
          </div>
          <div class="row">
            <div class="va-table-responsive">
              <table class="va-table" id="va-table-dayOverview">
                <thead>
                  <tr>
                    <th width="40px">&nbsp;</th>
                    <th width="90px">{{ $t('tagesarbeit.table.date') }}</th>
                    <th width="90px">{{ $t('tagesarbeit.table.time') }}</th>
                    <th>&nbsp;P&nbsp;</th>
                    <th width="100px">{{ $t('tagesarbeit.table.name') }}</th>
                    <th width="250px">{{ $t('tagesarbeit.table.comment') }}</th>
                    <th>{{ $t('common.registrant') }}</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </va-card>
        <div class="row" v-for="(ev,evK) in eventList" :key="evK">
          <div class="flex md12">
            <va-card outlined :color="ev.weekDay=='Sa' ? '#F6E3CE' : '#E0F8EC'" >
              <div class="row">
                <div class="va-table-responsive">
                  <table class="va-table" id="va-table-dayOverview">
                    <tbody>
                      <tr v-for="(usrEv,usrEvK) in ev.userEvents" :key="usrEvK">
                        <td :rowspan="ev.eventsInDay" v-if="usrEv.dayIdx==1" width="40px">{{ ev.weekDay }}</td>
                        <td :rowspan="ev.eventsInDay" v-if="usrEv.dayIdx==1" width="90px">{{ ev.eventDay }}<br><br>woche: {{ ev.weekWorkers }}<br>tag: {{ ev.dayWorkers }}</td>
                        <td width="95px">{{ usrEv.selectedTime }}</td>
                        <td>{{ usrEv.workersCount }}</td>
                        <td>{{ usrEv.userName }}</td>
                        <td>{{ usrEv.comments }}</td>
                        <td v-if="usrEv.orgUserName">{{ usrEv.orgUserName }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </va-card>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'uebersichtbau',
  data () {
    this.getAllDayEvents()
    return {
      eventList: {},
      currState: {
        msgType: '',
        msg: '',
      },
    }
  },
  methods: {
    getAllDayEvents () {
      this.$srvconn.sendMessage('getAllDayEvents')
        .then(response => {
          if (response.msgType === 'getAllDayEvents') {
            this.eventList = response.userData.allDayEvents
          }
          // console.log(response)
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style>
  #va-table-dayOverview td {
    padding: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border: 1px solid #dddddd;
  }

  .uebersichtbau .va-card {
    color: #000000 !important;
  }
</style>
